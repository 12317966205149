import { View, Text, Link } from '@upstox/upstox-ui';
import React, { FunctionComponent } from 'react';
import * as css from './Level2Menu.style';

const Level2Menu: FunctionComponent = () => {
  return (
    <View css={css.wrapper}>
      <a href={process.env.MF_FRONTEND_URL}>
        <Text weight="semiBold" color="text.2">
          Mutual Funds
        </Text>
      </a>
      <a href={`${process.env.BASE_URL}/ipo-initial-public-offering`}>
        <Text weight="bold">IPO</Text>
      </a>
    </View>
  );
};

export default Level2Menu;
