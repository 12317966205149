import { spacing, color } from '@upstox/upstox-ui';

export const wrapper: CSSFunction = ({ theme }) => ({
  width: '100%',
  backgroundColor: '#f5f5f5',
  padding: spacing(theme, [0, 'medium', 0]),

  '@media (min-width: 1024px)': {
    padding: spacing(theme, [0, 'xxxlarge', 0]),
  },
  '& > a': {
    textDecoration: 'none !important',
    marginRight: spacing(theme, [36]),
    padding: spacing(theme, [15, 0]),
    '&:last-child': {
      borderBottom: '2px solid #000',
    },
    '& > span': {
      fontSize: 16,
    },
  },
});
