import React, { FunctionComponent } from 'react';
import {
  View,
  Text,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  ContainedIcon,
} from '@upstox/upstox-ui';
import { Ipo } from '../../common/store/types';
import moment from 'dayjs';
import NextLink from 'next/link';
import { IPO_STATUS } from '../../common/constants';
import { observer } from 'mobx-react';
import { ListStatus } from './types';
import IPOListCard from '../IPOListCard';
import useCurrentWidth from '../../common/hooks/useCurrentWidth';
import * as css from './IPOListing.style';

interface Props {
  title: string;
  description?: string | React.ReactNode;
  wrapperClassName?: string;
  ipos: Ipo[];
  status: ListStatus;
}

const IPOListing: FunctionComponent<Props> = ({
  title,
  description,
  wrapperClassName = '',
  ipos,
  status,
}) => {
  const biddingDate = (startDate: string, endDate: string) => {
    if (startDate && startDate.length > 0 && endDate && endDate.length > 0) {
      return `${moment(startDate).format('DD')}-${moment(endDate).format(
        'DD MMM.',
      )}`;
    } else {
      return 'To be announced';
    }
  };

  const priceRange = (minPrice: number, maxPrice: number) => {
    if (minPrice && maxPrice) {
      return `₹${minPrice}-${maxPrice}`;
    } else {
      return '-';
    }
  };

  const screenWidth = useCurrentWidth();

  return (
    <View flexDirection="column" className={`full-width ${wrapperClassName}`}>
      <View flexDirection="column">
        <Text as="h2" variant="bodyBold">
          {title}
        </Text>
        {description && <Text>{description}</Text>}
      </View>

      {!screenWidth || screenWidth >= 768 ? (
        <Table>
          <TableHead divider>
            <TableRow>
              <TableCell>
                <Text size="meta" color="text.2">
                  Name
                </Text>
              </TableCell>
              <TableCell>
                <Text size="meta" color="text.2">
                  Bidding dates
                </Text>
              </TableCell>
              <TableCell align="right">
                <Text size="meta" color="text.2">
                  Price range
                </Text>
              </TableCell>
              {['active', 'upcoming'].includes(status) && (
                <TableCell>
                  <Text size="meta" color="text.2">
                    Lot size
                  </Text>
                </TableCell>
              )}
              {status === 'active' && (
                <TableCell>
                  <Text size="meta" color="text.2">
                    Stage
                  </Text>
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {ipos &&
              ipos.map((ipo) => (
                <TableRow divider key={ipo.ipoId}>
                  <TableCell verticalAlign="middle">
                    <View alignItems="center" gap={16}>
                      <ContainedIcon
                        variant="primary"
                        style={{
                          borderRadius: '50%',
                          overflow: 'hidden',
                          width: '40px',
                          height: '40px',
                        }}
                      >
                        {ipo?.logoUrl ? (
                          <img
                            src={ipo.logoUrl}
                            alt={
                              ipo?.logoMeta && ipo?.logoMeta.alt
                                ? ipo?.logoMeta.alt
                                : ''
                            }
                            title={
                              ipo?.logoMeta && ipo?.logoMeta.title
                                ? ipo?.logoMeta.title
                                : ''
                            }
                            style={{ maxWidth: '100%', width: '100%' }}
                          />
                        ) : (
                          <Avatar
                            name={ipo.name}
                            as="div"
                            style={{ maxWidth: '100%' }}
                          />
                        )}
                      </ContainedIcon>
                      <View flexDirection="column" css={css.ipoName}>
                        <a
                          href={`${process.env.BASE_URL}/ipo-initial-public-offering/${ipo.seoName}`}
                        >
                          <Text as="h3">{ipo.name}</Text>
                        </a>
                        <Text variant="metaBold">{ipo.symbol}</Text>
                      </View>
                    </View>
                  </TableCell>
                  <TableCell verticalAlign="middle">
                    <Text variant="metaBold" color="text.1">
                      {biddingDate(ipo.biddingStartDate, ipo.biddingEndDate)}
                    </Text>
                  </TableCell>
                  <TableCell align="right" verticalAlign="middle">
                    <Text size="meta">
                      {priceRange(ipo.minPrice, ipo.maxPrice)}
                    </Text>
                  </TableCell>
                  {['active', 'upcoming'].includes(status) && (
                    <TableCell verticalAlign="middle">
                      <Text size="meta">
                        {ipo.lotSize ? `${ipo.lotSize} shares` : '-'}
                      </Text>
                    </TableCell>
                  )}
                  {status === 'active' && (
                    <TableCell verticalAlign="middle">
                      <Text size="meta">
                        {ipo.status === IPO_STATUS.preApply
                          ? 'Pre-apply'
                          : ipo.status === IPO_STATUS.closed
                          ? 'Closed'
                          : 'Open now'}
                      </Text>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <>
          {ipos &&
            ipos.map((ipo) => (
              <IPOListCard
                key={ipo.ipoId}
                ipoName={ipo.name}
                logoMeta={ipo.logoMeta}
                price={priceRange(ipo.minPrice, ipo.maxPrice)}
                biddingDates={biddingDate(
                  ipo.biddingStartDate,
                  ipo.biddingEndDate,
                )}
                logoURL={ipo.logoUrl}
                status={
                  status === 'active'
                    ? ipo.status === IPO_STATUS.preApply
                      ? 'Pre-apply'
                      : ipo.status === IPO_STATUS.closed
                      ? 'Closed'
                      : 'Open now'
                    : ''
                }
                ipoId={ipo.ipoId}
                seoName={ipo.seoName}
                tagColor={
                  ipo.status === IPO_STATUS.preApply
                    ? 'yellow'
                    : ipo.status === IPO_STATUS.closed
                    ? 'grey'
                    : 'yellow'
                }
              />
            ))}
        </>
      )}
    </View>
  );
};

export default observer(IPOListing);
