import React, { FunctionComponent } from 'react';
import {
  Card,
  ContainedIcon,
  View,
  Text,
  Tag,
  Avatar,
} from '@upstox/upstox-ui';
import * as css from './IPOListCard.style';
import Image from 'next/image';

interface Props {
  ipoName: string;
  price: string;
  biddingDates: string;
  status: string;
  logoURL: string;
  ipoId: string;
  seoName: string;
  logoMeta?: {
    title?: string;
    alt?: string;
  };
  tagColor: TagColors;
}

const IPOListCard: FunctionComponent<Props> = ({
  status,
  ipoName,
  price,
  biddingDates,
  logoURL,
  ipoId,
  seoName,
  tagColor,
  logoMeta,
}) => {
  return (
    <View css={css.listCard}>
      <Card width="full" style={{ borderRadius: '8px' }}>
        <View flexDirection="column" css={css.cardContent}>
          <View css={css.badge} justifyContent="flex-end">
            {status && (
              <Tag variant={tagColor} transform="none" size="small">
                {status}
              </Tag>
            )}
          </View>
          <View alignItems="center" css={css.ipoName}>
            <View css={css.ipoLogo}>
              <ContainedIcon
                variant="primary"
                style={{ borderRadius: '50%', overflow: 'hidden' }}
              >
                {logoURL ? (
                  <img
                    src={logoURL}
                    alt={logoMeta && logoMeta.alt ? logoMeta.alt : ''}
                    title={logoMeta && logoMeta.title ? logoMeta.title : ''}
                    style={{ maxWidth: '100%' }}
                  />
                ) : (
                  // <Image src={logoURL} alt={ipoName} />
                  <Avatar
                    name={ipoName}
                    as="div"
                    style={{ width: '100%', height: '100%' }}
                  />
                )}
              </ContainedIcon>

              {/* {logoURL ? <Avatar image={logoURL} as="div" /> : <Avatar name={ipoName} as="div" /> } */}
            </View>
            <View flexDirection="column" css={css.ipoInfo}>
              <a
                href={`${process.env.BASE_URL}/ipo-initial-public-offering/${seoName}`}
              >
                <Text
                  as="h2"
                  variant="body"
                  style={{ fontSize: 16 }}
                  lineHeight="s"
                >
                  {ipoName}
                </Text>
              </a>
              <Text color="text.2" weight="meta">
                {price}
              </Text>
            </View>
          </View>
          <View alignItems="center">
            <View margin={[0, 5, 0, 0]}>
              <Text color="text.2" size="s" variant="meta">
                Bidding dates
              </Text>
            </View>
            <View>
              <Text color="text.2" variant="metaBold" size="s">
                {biddingDates}
              </Text>
            </View>
          </View>
        </View>
      </Card>
    </View>
  );
};

export default IPOListCard;
