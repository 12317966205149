import { spacing } from '@upstox/upstox-ui';

export const ipoLogo: CSSFunction = ({ theme }) => ({
  marginRight: spacing(theme, ['medium']),
});

export const ipoName: CSSFunction = ({ theme }) => ({
  width: '100%',
  // marginTop: spacing(theme, ['small']),
  marginBottom: spacing(theme, ['small']),
});

export const cardContent: CSSFunction = ({ theme }) => ({
  padding: spacing(theme, [0, 'large', 'medium', 'medium']),
  position: 'relative',
  width: '100%',
});

export const badge: CSSFunction = ({ theme }) => ({
  // // position: 'absolute',
  // top: 8,
  // right: 8,
  paddingTop: spacing(theme, ['xsmall']),
  marginRight: '-10px',
  minHeight: '22px',
});
export const listCard: CSSFunction = ({ theme }) => ({
  marginTop: spacing(theme, ['medium']),
});

export const ipoInfo: CSSFunction = ({ theme }) => ({
  wordBreak: 'break-word',
});
