import {
  View,
  PageCover as PageCoverUpstox,
  Text,
  Card,
} from '@upstox/upstox-ui';
import React, { FunctionComponent } from 'react';
import useCurrentWidth from '../../common/hooks/useCurrentWidth';
import * as css from './PageCover.style';

const PageCover: FunctionComponent = () => {
  const screenWidth = useCurrentWidth();

  return (
    <View className="full-width">
      <View flexDirection="column" className="full-width">
        <Card
          color="gold"
          rounded={false}
          width="full"
          variant="secondary"
          css={css.wrapper}
        >
          <View justifyContent="space-between" alignItems="center">
            <View flexDirection="column">
              <Text as="h1" weight="bold" variant="heading2" className="mb8">
                Invest in IPOs
              </Text>
              <Text weight="semiBold">
                Apply to invest in companies before they get listed, easily via
                UPI.
              </Text>
            </View>
            <View css={css.bannerImage}>
              <img
                src={'/ipo-initial-public-offering/banner-image.svg'}
                alt=""
              />
            </View>
          </View>
        </Card>
      </View>
    </View>
  );
};

export default PageCover;
