import React, { FunctionComponent } from 'react';
import { Card, Link, Text, View } from '@upstox/upstox-ui';
import * as css from './HowToApply.style';
import NextLink from 'next/link';

interface Props {
  wrapperClassName?: string;
}

const HowToApply: FunctionComponent<Props> = ({ wrapperClassName = '' }) => {
  return (
    <Card variant="secondary" className={wrapperClassName}>
      <View css={css.wrapper} flexDirection="column">
        <Text variant="heading2">Applying for an IPO for the first time?</Text>

        {/* <NextLink> */}
        <a
          className="mt5"
          target="_blank"
          href="https://help.upstox.com/support/solutions/articles/248990-how-can-i-apply-for-an-ipo-"
          rel="noreferrer"
        >
          <Text color="text.link" variant="bodyBold">
            Learn more about how to apply
          </Text>
        </a>
        {/* </NextLink> */}
      </View>
    </Card>
  );
};

export default HowToApply;
