import { spacing, color } from '@upstox/upstox-ui';

export const wrapper: CSSFunction = ({ theme }) => ({
  width: '100%',
  backgroundColor: '#FCBC69',
  padding: spacing(theme, ['large', 'medium']),

  '@media (min-width: 1024px)': {
    padding: spacing(theme, ['large', 'xxxlarge']),
  },
});

export const bannerImage: CSSFunction = () => ({
  '& > img': {
    '@media (max-width: 767px)': {
      width: 110,
    },
  },
});
