import React, { FunctionComponent } from 'react';
import { Card, Link, Text, View } from '@upstox/upstox-ui';
import * as css from './IPOListingFooter.style';

const IPOListingFooter: FunctionComponent = ({}) => {
  return (
    <View css={css.ipoContentCard}>
      <Card css={css.cardStyles}>
        <View flexDirection="column" css={css.cardContent}>
          <Text as="h2" variant="bodyBold" color="text.default">
            What is an IPO?
          </Text>
          <Text variant="body" color="text.2">
            When a private corporation issues stock for the very first time to
            the public, the process is called an Initial Public Offering (IPO).
            When a company wants to raise money, it can issue a stock for sale
            by issuing either Debt or Equity. With Equity, the first offering is
            made through listing the shares for the public in the stock market.
            This first offering is called an Initial Public Offering and this is
            when the company goes public.
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            What are the steps of the IPO process in India?
          </Text>
          <Text variant="body" color="text.2">
            The IPO process in India has various steps that a private
            corporation takes to go public.
            <View as="ol" flexDirection="column" css={css.orderedListNumber}>
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Investment Bank Selection:
                </Text>
              </Text>
              Selecting the underwriter investment bank is the very first step.
              The underwriter bank helps the company establish the amount of
              funds that need to be raised, the type of securities that can be
              offered and the initial price that is set per share. For larger
              stocks, there may be more than one investment bank.
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Red Herring Prospectus:
                </Text>
              </Text>
              The RHP or Red Herring Prospectus is the document that includes
              diverse information about the company like financial records,
              future plans, current offerings, investors and stakeholders,
              potential risks, strengths and weaknesses and more. This document
              is created with the help of the underwriters (investment banks)
              and then sent for SEBI approvals. Many times, underwriters use
              this document to attract potential institutional investors as
              well.
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  SEBI Approval:
                </Text>
              </Text>
              The RHP is then presented to the Securities and Exchange Board of
              India (SEBI) for approval. If they are satisfied and give the
              green light, the process can begin. The SEBI provides a date and
              time for the release. In the case that SEBI is not satisfied, it
              asks the underwriters and companies to make the changes and
              resubmit the proposal.
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Stock Exchange Approval:
                </Text>
              </Text>
              To list the IPO on either of the stock exchanges - BSE or NSE,
              another approval needs to be seeked. The BSE, for example, has a
              listing department that is in charge of providing approval, who in
              turn have a list of criteria that beget the approval.
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Subscription of Shares:
                </Text>
              </Text>
              Once all the approvals are gained, the corporate can open their
              stocks to investors, This is done as per the pre-approved dates
              provided in the prospectus. The investors then need to fill out
              the Initial Public Offerings application form to be eligible to
              subscribe to the offerings.
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Listing:
                </Text>
              </Text>
              As per the price band, the investors put in their bids. The
              offerings are then allotted to the successful investors and
              credited to their demat accounts. The stock is then listed on the
              BSE or NSE. Shares are allotted to investors as per availability,
              so in the case of over subscription, fewer than demanded shares
              may be allotted or sometimes none at all and the remaining money
              is refunded.
            </View>
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            Why does a company launch an IPO?
          </Text>
          <Text variant="body" color="text.2">
            Generally, a company decides to launch an IPO and go public for one
            of the following reasons.
            <View as="ol" flexDirection="column" css={css.orderedListNumber}>
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Capital requirement for growth and expansion:
                </Text>
              </Text>
              When a corporation is ready to increase its operations and
              productivity it requires capital funds. To find more investment,
              going public can be a great way to expand.
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Early investors and owners want to sell their stake:
                </Text>
              </Text>
              Going public can be a good exit strategy for already existing
              owners, venture capitalists and stakeholders. Sale of stock makes
              the company's funds more liquid, and venture capitalists can sell
              their funds in return for higher benefits and leave the
              organisation with these returns.
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Creating Public Awareness:
                </Text>
              </Text>
              IPOs create buzz. They provide for publicity, events and are
              listed with stars on the stock exchange. The public is sure to
              hear and see more about the company this way, spreading awareness
              of its operations.
            </View>
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            How to invest in an IPO?
          </Text>
          <Text variant="body" color="text.2">
            To start your{' '}
            <Link
              href="https://upstox.com/learning-center/ipo/how-to-invest-in-an-ipo/"
              target="_blank"
            >
              investment in IPO
            </Link>{' '}
            -
            <View as="ol" flexDirection="column" css={css.orderedListNumber}>
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Ask yourselves these three questions:
                </Text>
              </Text>
              <View
                as="ol"
                flexDirection="column"
                css={css.orderedListAlphabet}
              >
                <Text as="li" color="text.2">
                  How much do you want to invest?
                </Text>
                <Text as="li" color="text.2">
                  Can you invest that much?
                </Text>
                <Text as="li" color="text.2">
                  What is the risk level you can take on?
                </Text>
                <Text as="li" color="text.2">
                  What is your financial objective and does this type of an
                  instrument align with it?
                </Text>
              </View>
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Create the following accounts:
                </Text>
              </Text>
              <View
                as="ol"
                flexDirection="column"
                css={css.orderedListAlphabet}
              >
                <Text as="li" color="text.2">
                  <Link
                    href="https://upstox.com/open-demat-account/"
                    target="_blank"
                  >
                    Demat Account
                  </Link>
                  : A dematerialised or electronic account that is used to store
                  all your shares.
                </Text>
                <Text as="li" color="text.2">
                  <Link href="https://upstox.com/" target="_blank">
                    Trading Account
                  </Link>
                  : For investing online, one needs a trading account so that
                  they can trade in the market according to their preferences.
                </Text>
                <Text as="li" color="text.2">
                  Bank Account: Any investment needs a bank account, so that the
                  payment can be debited and credited upon buying and selling of
                  stock.
                </Text>
              </View>
              <Text as="li">
                <Text as="h3" weight="heading2" color="text.default">
                  Apply via ASBA facility that is provided by the bank:
                </Text>
              </Text>
              <View
                as="ol"
                flexDirection="column"
                css={css.orderedListAlphabet}
              >
                <Text as="li" color="text.2">
                  Log onto your netbanking account.
                </Text>
                <Text as="li" color="text.2">
                  Click on the IPOs option in the requests tab and apply for any
                  of the ones under the list that opens.
                </Text>
                <Text as="li" color="text.2">
                  Submit information regarding the number of shares and bid
                  price.
                </Text>
                <Text as="li" color="text.2">
                  Accept the terms and conditions and go ahead with the
                  transaction. This will block the transaction amount in your
                  bank and once the IPO application is accepted, the amount will
                  be debited. If rejected, the amount will be released.
                </Text>
              </View>
              <Text as="li" weight="heading2" color="text.default">
                <Text as="h3" weight="heading2" color="text.default">
                  Apply via UPI facility on Upstox:
                </Text>
              </Text>
              <View
                as="ol"
                flexDirection="column"
                css={css.orderedListAlphabet}
              >
                <Text as="li" color="text.2">
                  While ASBA requires logging onto your netbanking, the UPI
                  facility can be done through your trading account on Upstox.
                </Text>
                <Text as="li" color="text.2">
                  Select the Initial Public Offering that you want to invest in.
                </Text>
                <Text as="li" color="text.2">
                  Submit the number of shares and your bid price for the same.
                </Text>
                <Text as="li" color="text.2">
                  Fill in your personal information including your UPI ID.
                </Text>
                <Text as="li" color="text.2">
                  Complete the form, accept the terms and conditions and clear
                  the transaction. The money will be blocked and debited only
                  after acceptance of the application.
                </Text>
              </View>
            </View>
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            How to check the allotment status?
          </Text>
          <Text variant="body" color="text.2">
            The allotment status of an IPO can only be checked after 7 working
            days after the issue closing date. To do this:
            <View as="ol" flexDirection="column" css={css.orderedListNumber}>
              <Text as="li" color="text.2">
                Login to your{' '}
                <Link href="https://upstox.com/" target="_blank">
                  Upstox app
                </Link>{' '}
                using your biometrics or 6 digit pin.
              </Text>
              <Text as="li" color="text.2">
                Click on the discover tab at the bottom and click ‘invest in
                IPO’. Here you can check the ones you have applied for as well
                as its allotment status.
              </Text>
              <Text as="li" color="text.2">
                Once you click on ‘invest in IPOs’ a tab will open with two
                categories - Ongoing and My Applications. Click on My
                Applications.
              </Text>
              <Text as="li" color="text.2">
                Click on the offering that you want to check the allotment
                status for. If it has been allotted the tab will say ‘You got
                the IPO’.
              </Text>
              <Text as="li" color="text.2">
                To see your investment journey completely, click on the status
                and that is it.
              </Text>
            </View>
            Once the shares are allotted successfully, they will be credited to
            your demat account on the next working day on Upstox app. In case
            the allotment is unsuccessful, the refund will be processed within
            the next ten working days.
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            What is an open and close date?
          </Text>
          <Text variant="body" color="text.2">
            Specific dates are declared by the company that are open to the
            public for the buying of shares. In most cases, this subscription is
            open only on three working days. The day on which the subscription
            starts is called the open date and the day on which the subscription
            ends is called the close date.
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            How to apply for an IPO through Upstox?
          </Text>
          <Text variant="body" color="text.2">
            The application apply for an IPO on Upstox is very simple and easy
            to execute. Just follow these simple steps:
            <View as="ol" flexDirection="column" css={css.orderedListNumber}>
              <Text as="li" color="text.2">
                Login to your Upstox App with your 6 digit pin or biometrics.
              </Text>
              <Text as="li" color="text.2">
                Click on the ‘Discover’ tab at the bottom of the screen, then
                click ‘Invest in IPO’.
              </Text>
              <Text as="li" color="text.2">
                Now select the stock that you wish to bid on and you will be
                redirected to a screen with two tabs - ‘Overview’ and
                ‘Timeline’. These provide more information about the stock,
                which you can read or skip by simply clicking on ‘Apply’. (The
                apply button is disabled during non-market hours and enabled
                only between 10am - 5pm).
              </Text>
              <Text as="li" color="text.2">
                This opens the application page, which you can now fill out with
                your information. After this, click on ‘continue’.
              </Text>
              <Text as="li" color="text.2">
                Enter your UPI ID and click ‘confirm’.
              </Text>
              <Text as="li" color="text.2">
                Check your application status by clicking on ‘status’ and on
                this screen you can also see a UPI mandate payment pending
                message. This means that your bank is supposed to release the
                funds automatically on or before the mandate expiry date if you
                do not get the allotment of shares that you have applied for. In
                the case that this does not happen, you can raise an issue on
                ipo.upi@npci.org.in.
              </Text>
              <Text as="li" color="text.2">
                Accept the mandate request and now you can check your allotment
                status under the ‘status’ tab.
              </Text>
              <Text as="li" color="text.2">
                You can also check your entire investment journey - the timeline
                of your application along with date details.
              </Text>
            </View>
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            What are the advantages of applying for an IPO through Upstox?
          </Text>
          <Text variant="body" color="text.2">
            Upstox is an online platform that strives to make your investment
            plans much easier while providing you all the information you need
            in one place. There are plenty of reasons to apply through Upstox.
            <View as="ol" flexDirection="column" css={css.orderedListNumber}>
              <Text as="li" color="text.2">
                Skip offline paperwork that is a long and tedious process that
                can be very intensive.
              </Text>
              <Text as="li" color="text.2">
                Using Upstox is hassle free and simple. It takes only a few
                minutes at the most.
              </Text>
              <Text as="li" color="text.2">
                The shares are allocated and credited automatically to your
                registered demat account and there is no need for you to follow
                up or use any other platform to check your allotment status.
              </Text>
              <Text as="li" color="text.2">
                You can view all of your bought shares in one place on your
                Upstox account.
              </Text>
              <Text as="li" color="text.2">
                All the important information can be found on Upstox, you can
                check the price bands, upcoming & recently listed IPOs, and
                more.
              </Text>
            </View>
          </Text>
          <Text as="h2" variant="bodyBold" color="text.default">
            How to get updates on upcoming IPOs?
          </Text>
          <Text variant="body" color="text.2">
            You can find updates on upcoming IPOs right here, on Upstox. We
            strive to bring to you details, reviews & news on every Initial
            Public Offerings fast and accurately. You can also subscribe to our
            alerts so we can push notifications on your mobile and desktop.
            Apart from Upstox, the information is available on the NSE or BSE
            website.
          </Text>
        </View>
      </Card>
    </View>
  );
};

export default IPOListingFooter;
