import { spacing, color } from '@upstox/upstox-ui';

export const ipoContentCard: CSSFunction = ({ theme }) => ({
  width: '100%',
  margin: '40px 0',
});

export const cardStyles: CSSFunction = ({ theme }) => ({
  backgroundColor: color(theme, 'ui1'),
  borderRadius: '16px',
  width: '100%',
});

export const cardContent: CSSFunction = ({ theme }) => ({
  width: '100%',
  maxHeight: '403px',
  padding: '16px',
  fontWeight: '600',
  fontSize: '18px',
  overflowY: 'scroll',

  '@media (min-width:1000px)': {
    padding: '32px 56px 32px 32px',
  },

  '@media (min-width:767px) and (max-width:999px)': {
    padding: '24px',
  },
});

export const orderedListNumber: CSSFunction = ({ theme }) => ({
  listStyleType: 'decimal',
  margin: spacing(theme, ['small', 'small', 'small', 'xlarge']),
});

export const orderedListAlphabet: CSSFunction = ({ theme }) => ({
  listStyleType: 'lower-latin',
  margin: spacing(theme, ['none', 'none', 'none', 'xlarge']),
});
