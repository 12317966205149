import type { GetServerSideProps, NextPage } from 'next';
import Layout from '../components/Layout';
import {
  View,
  Text,
  Link,
  Loading,
  Card,
  Breadcrumbs,
} from '@upstox/upstox-ui';
import IPOListing from '../components/IPOListing';
import HowToApply from '../components/HowToApply';
import { observer, inject } from 'mobx-react';
import { fetchAllIpos } from '../common/store/ipo.service';
import { useCallback, useEffect } from 'react';
import NextLink from 'next/link';
import { IPO_QUERY_STATUS } from '../common/constants';
import { IpoStore } from '../common/store/ipo.store';
import Head from 'next/head';
import PageCover from '../components/PageCover';
import { CONSTANTS } from '../constants';
import mixpanel from '../services/mixpanel';
import router from 'next/router';
import Level2Menu from '../components/Level2Menu';
import Faqs from '../common/components/IPODetails/Faqs';
import IPOListingFooter from '../components/IPOListingFooter';

type Props = {
  data: any;
  seo: true | false;
  ipoStore: IpoStore;
};

const breadcrumbSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': `${process.env.BASE_URL}`,
        name: 'Trading',
      },
    },
    {
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': `${process.env.BASE_URL}/ipo-initial-public-offering`,
        name: 'IPO',
      },
    },
  ],
});

const Home = (props: Props) => {
  const {
    upcomingIpos,
    ongoingIpos,
    listedIpos,
    fetchIpoList,
    isLoadingIpoList,
  } = props.ipoStore;

  const refetch = useCallback(() => {
    return (
      (!upcomingIpos || upcomingIpos.length == 0) &&
      (!ongoingIpos || ongoingIpos.length == 0) &&
      (!listedIpos || listedIpos.length == 0)
    );
  }, [listedIpos, ongoingIpos, upcomingIpos]);

  useEffect(() => {
    if (typeof window != 'undefined') {
      mixpanel.track('IPO Landing Page Visited');
    }
  }, []);

  useEffect(() => {
    if (typeof window != 'undefined' && refetch())
      fetchIpoList(
        [
          IPO_QUERY_STATUS.active,
          IPO_QUERY_STATUS.preapply,
          IPO_QUERY_STATUS.listed,
          IPO_QUERY_STATUS.upcoming,
          IPO_QUERY_STATUS.closed,
        ],
        3,
      );
  }, [fetchIpoList, refetch]);

  const renderPageHeader = () => {
    return (
      <>
        <PageCover />
        <Level2Menu />
      </>
    );
  };

  const getRobotsTag = () => {
    return props.seo ? (
      <meta name="robots" content="all" />
    ) : (
      <meta name="robots" content="noindex,nofollow" />
    );
  };

  const IPOListingFaqs = [
    {
      question: 'What is an IPO investment?',
      answer: `
        <p>
          When a company offers its shares for the first time to the public by listing in the stock exchange (BSE / NSE), it is called an Initial Public Offering. Now, apart from venture capitalists, owners and angel investors, the public can also buy shares of the company. An IPO to be sold needs the compliance of SEBI. IPO investment is investing in shares of companies that are listing their stocks in the exchange market (BSE / NSE) for the very first time.
        </p>
      `,
      serialNo: 1,
    },
    {
      question: 'What does IPO mean?',
      answer: `
        <p>
          The <a href="https://upstox.com/learning-center/ipo/how-does-an-ipo-work/" target="_blank">IPO full form</a> is Initial Public Offering. When a private corporation issues stock for the very first time to all investors, the process is called an Initial Public Offering. When a company wants to raise money, it can issue a stock for sale by issuing either Debt or Equity. With Equity, the first offering is made through listing the stock for the public in the stock market. This first offering is when the corporation goes public.
        </p>
      `,
      serialNo: 2,
    },
    {
      question: 'What does upcoming IPOs mean?',
      answer: `
        <p>
          Upcoming IPOs are those that have already submitted the DRHP and are set to release in the next two weeks to a month’s time. This list helps investors know which are the upcoming offerings that they can bid for in the coming immediate period of time.
        </p>
      `,
      serialNo: 3,
    },
    {
      question: 'What does recently listed IPOs mean?',
      answer: `
        <p>
          An IPO goes through various stages before it can be listed in the BSE or NSE market. They are listed after the bidding process comes to an end and all the shares have been allotted to the investors whose bids were successful. Once they are listed, they are called recently listed IPOs and are available on the BSE and NSE markets. These can be listed at both premium, i.e. higher than the issue price, and discounted prices, i.e. lower than the issue price.
        </p>
      `,
      serialNo: 4,
    },
    {
      question: 'What is issue size?',
      answer: `
        <p>
          The Issue Size or Lot Size is the minimum number of shares that an investor can bid on. The issue size will be the number of shares in a bid for 1, so an investor can bid for 1 or multiples of 1. The issue size is decided as per the amount of money that needs to be raised by the company. For example, the issue size of the Zomato IPO was 195 shares, so a minimum bid needed was for all 195 of them at least. 
        </p>
      `,
      serialNo: 5,
    },
    {
      question: 'What is Price Band?',
      answer: `
        <p>
          The company that is issuing an IPO declares a price range for the shares being deployed into the market. This price range is called the Price Band. Let us say the Price Band of company X is Rs. 79 - Rs. 83. The investors can now bid as per this price band. If the shares are in heavy demand the company can choose to release them at Rs. 83, and those bidders that bid as per this amount are selected for subscription.
        </p>
      `,
      serialNo: 6,
    },
    {
      question: 'What is DRHP?',
      answer: `
        <p>
          Any company that wants to release an IPO must collect and submit all the important information required for approval by SEBI. The document used to compile all this data methodically is known as DRHP or Draft Red Herring Prospectus. The DRHP is available to all investors to know more about the company before putting in their bid. This document includes the nature of business, risks, strengths, weaknesses, promoters and more. The stock can be released only after the SEBI approval comes through.
        </p>
      `,
      serialNo: 7,
    },
    {
      question: 'What is RHP?',
      answer: `
        <p>
          The RHP or Red Herring Prospectus is the document that includes diverse information about the company like financial records, future plans, current offerings, investors and stakeholders, potential risks, strengths and weaknesses and more. This document is created with the help of the underwriters (investment banks) and then sent for SEBI approvals. Many times, underwriters use this document to attract potential institutional investors as well.
        </p>
      `,
      serialNo: 8,
    },
    {
      question: 'Who can invest?',
      answer: `
        <ol>
          Investors can be any adult that is able to engage in a contract. Investors are qualified into mainly 3 types:
          <li>Qualified Institutional Buyers (QIBs): Mutual funds, Domestic financial institutions like banks, financial institutions and insurance companies and more.</li>
          <li>Non-institutional Investors: Corporates and Individuals.</li>
          <li>Retail Institutional Investors</li>
        <ol/>
      `,
      serialNo: 9,
    },
    {
      question: 'Why is Upstox the best platform to apply for an IPO?',
      answer: `
        <ol>
          Upstox is an online platform that strives to make your investment plans much easier while providing you all the information you need in one place. There are plenty of reasons to apply through Upstox.
          <li>Skip offline paperwork that is a long and tedious process that can be very intensive.</li>
          <li>Using Upstox is hassle free and simple. It takes only a few minutes at the most.</li>
          <li>The stock is allocated and credited automatically to your registered demat account and there is no need for you to follow up or use any other platform to check your allotment status.</li>
          <li>You can view all of your investments in one place on your Upstox account.</li>
          <li>All the important information can be found on Upstox, you can check the price bands, upcoming & recently listed IPOs, and more.</li>
        <ol/>
      `,
      serialNo: 10,
    },
    {
      question: 'What is pre-apply?',
      answer: `
        <p>
          Some private investors are eligible to apply for the IPO 3 - 4 days before the bidding process starts. They are able to do so at a reduced price and procure pre-bid. This is to make sure that they do not miss out on the bid. Once it goes live, a UPI will be sent to the investor, which he may accept as to put in his bid once it starts.
        </p>
      `,
      serialNo: 11,
    },
  ];

  return (
    <>
      <Head>
        {getRobotsTag()}
        <meta name="description" content={CONSTANTS.LANDING_META_DESCRIPTION} />
        <link
          rel="canonical"
          href={`${process.env.BASE_URL}/ipo-initial-public-offering`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: breadcrumbSchema,
          }}
        ></script>
      </Head>

      <Layout
        pageTitle={CONSTANTS.LANDING_PAGE_TITLE}
        pageHeader={renderPageHeader()}
      >
        {isLoadingIpoList ? (
          <View
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Loading className="mb20" color="text.2" />
          </View>
        ) : (
          <>
            <View className="mt40 mb30">
              <Breadcrumbs>
                <Link href={process.env.BASE_URL}>
                  <a>Trading</a>
                </Link>
                <Text>IPO</Text>
              </Breadcrumbs>
            </View>
            {ongoingIpos.length > 0 && (
              <IPOListing
                title="Ongoing IPOs"
                ipos={ongoingIpos}
                description={
                  <>
                    <Text size="meta" color="text.2">
                      New to IPO investing?{' '}
                    </Text>
                    {/* <NextLink> */}
                    <a
                      target="_blank"
                      href="https://help.upstox.com/support/solutions/articles/248990-how-can-i-apply-for-an-ipo-"
                    >
                      <Text color="text.link" variant="meta">
                        Learn more about applying to IPO
                      </Text>
                    </a>
                    {/* </NextLink> */}
                  </>
                }
                // wrapperClassName="mt45"
                status="active"
              />
            )}

            <HowToApply wrapperClassName="mt45" />

            {upcomingIpos.length > 0 && (
              <IPOListing
                title="Upcoming IPOs"
                ipos={upcomingIpos}
                wrapperClassName="mt45"
                status="upcoming"
              />
            )}

            {listedIpos.length > 0 && (
              <IPOListing
                title="Recently listed IPOs"
                ipos={listedIpos}
                wrapperClassName="mt45"
                status="listed"
              />
            )}

            <div className="mt40 full-width">
              <Faqs faqs={IPOListingFaqs} title="IPO FAQs" />
            </div>

            <IPOListingFooter />
          </>
        )}
      </Layout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  query,
  req,
  res,
}) => {
  // Fetch data from external API
  let data = [];
  const response = await fetchAllIpos();
  if (response.success) {
    data = response.data?.ipos;
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=1800, max-age=300, stale-while-revalidate=59',
    );
  } else {
    res.setHeader(
      'Cache-Control',
      'no-cache, no-store, max-age=0, must-revalidate',
    );
  }
  let seo = false;
  if (process.env?.env && process.env?.env?.toLowerCase() == 'prod') {
    seo = true;
  } else {
    seo = false;
  }
  // Pass data to the page via props
  return { props: { ipos: data, seo: seo } };
};

export default inject('ipoStore')(observer(Home));
